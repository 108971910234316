import React from "react";
import PropTypes from "prop-types";
import { Container } from "reactstrap";

import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Header from '../header/Header';
import MarkdownViewer from "./MarkdownViewer";

import "./HelpAbout.css";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function HelpAbout(props) {
  const [value, setValue] = React.useState(0);

//   const language = props.location.state.data;

  const filePath = "./UsersGuide_en.md" ;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container>
      <Header />
      <Tabs value={value} onChange={handleChange} centered>
        <Tab
          className="headerTabs"
          label="User's Guide"
          {...a11yProps(0)}
        />
        {/* <Tab className="headerTabs" label="Release Notes" {...a11yProps(1)} /> */}
      </Tabs>
      <TabPanel value={value} index={0}>
        <MarkdownViewer markdownFilePath={filePath} />
      </TabPanel>
      {/* <TabPanel value={value} index={1}>
        <MarkdownViewer markdownFilePath="./ReleaseNotes.md" />
      </TabPanel> */}
    </Container>
  );
}
