import utils from './utils'
import config from '../config'
export const getSignatureURL = async (token, env, conversationDetails) => {
    const uri = `${config.endpoints.backend}/playback/url`
    const body = {
        'recordingId': conversationDetails.recordingID,
        'conversationId': conversationDetails.conversationId,
        'yearWeek': conversationDetails.yearweek,
    }
    return utils.fetchWrapper(uri, {
        method: 'POST',
        headers: {
            'env': env,
            'token': token,
            'tokensource': 'purecloud',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
}
export const getConversationDetails = async (token, env, startDate, endDate, callerId, conversationId) => {
    const uri = `${config.endpoints.backend}/search/recording`
    const body = {
        'startDate' : startDate,
        'endDate' : endDate,
        'callerId' : callerId,
        'conversationId' : conversationId
    }
    return utils.fetchWrapper(uri, {
        method: 'POST',
        headers: {
            'env': env,
            'token': token,
            'tokensource': 'purecloud',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
}
export const recordingActions = async (token, env, conversationId, participantId, recording, agentName, queueName, retention) => {
    const uri = `${config.endpoints.backend}/triggerUserRecording`
    const body = {
        'conversationID': conversationId,
        'participantID':participantId,
        'recording':recording,
        'agentName' : agentName,
        'queueName' : queueName,
        'retention' : retention
    }
    return utils.fetchWrapper(uri, {
        method: 'POST',
        headers: {
            'env': env,
            'token': token,
            'tokensource': 'purecloud',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
}
export const deleteRecording = async (token, env, conversationDetails) => {
    const uri = `${config.endpoints.backend}/delete/recording`
    const body = {
        'recordingId': conversationDetails.recordingID,
        'conversationId': conversationDetails.conversationId,
        'yearWeek': conversationDetails.yearweek,
    }
    return utils.fetchWrapper(uri, {
        method: 'POST',
        headers: {
            'env': env,
            'token': token,
            'tokensource': 'purecloud',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
}
export const getMissedRecordings = async(token, env) => {
    const uri = `${config.endpoints.backend}/missedRecordings`
    const body = {}
    return utils.fetchWrapper(uri, {
        method: 'POST',
        headers: {
            'env': env,
            'token': token,
            'tokensource': 'purecloud',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
}
export default {
    getSignatureURL,
    getConversationDetails,
    recordingActions,
    deleteRecording,
    getMissedRecordings
}