const local = {
    en: {
      startButton: "Start Recording",
      stopButton: "Stop Recording",
      enterRetention: "Retention(in years)*",
      startMessage: "Recording started",
      stopMessage: "Recording stopped"
    },
    es: {
      startButton: "Iniciar la grabación",
      stopButton: "Para de grabar",
      enterRetention: "Retención(en años)*",
      startMessage: "Comenzó la grabación",
      stopMessage: "Grabación detenida"      
    },
    fr: {
        startButton: "Commencer l'enregistrement",
        stopButton: "Arrête d'enregistrer",
        enterRetention: "Rétention(en années)*",
        startMessage: "L'enregistrement a commencé",
        stopMessage: "Enregistrement arrêté"
    },
    de: {
        
    }
  };
  export { local };
  