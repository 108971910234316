import React, { useEffect, useState } from "react";
import { MainDiv, ParentDiv, WizardContent } from "../../../styles";
import Header from "../common/header";
import Loader from "../common/loader";
import ProductStatus from "../common/productsStatus/productStatus";
import ProgressBar from "../common/progressBar";
import WelcomeGreet from "../common/welcome-greet/welcome-greet";
import config from '../../../config'

const SummaryView = () => {
    let [isLoading, setLoader] = useState(true);
    const [productDetails, setProductDetails] = useState({ status: "", productStatus: "", statusIcon: "", btnIcon: "", msgContent: "", contentSize: ""});

    useEffect(() => {
        if (window.localStorage.getItem("addOrError")) {
            setProductDetails({
                status: "fail", productStatus: "Request not submitted successfully", statusIcon: "fas fa-times-circle",
                msgContent: `We have encountered an error while submitting your request for Snipped Recordings for Genesys Cloud - installation. `,
            });
        } else {
            setProductDetails({
                status: "success", productStatus: "Request submitted successfully", statusIcon: "fas fa-check-circle",
                msgContent: `Your request to install Snipped Recordings for Genesys Cloud was submitted successfully, you should receive a confirmation email from ${config.sendAsEmailAddr}. \nExpert App team member will reach out to you for additional details and will notify once the installation is complete.\n\nFor any questions related to this request, please reach out ${config.installationNotificationAddr}.`,
                contentSize: "large"
            });
        }
        window.localStorage.removeItem("addOrError");
        setLoader(false);
    }, [])

    return (
        <ParentDiv>
            <div id="background"></div>
            <Header tail="Summary"></Header>

            <WizardContent>
                {(isLoading) &&
                    <Loader></Loader>
                }
                <WelcomeGreet tabTitle="Done"></WelcomeGreet>
            </WizardContent>

            <ProgressBar tabName="summary"></ProgressBar>

            <MainDiv>
                <ProductStatus {...productDetails}></ProductStatus>
            </MainDiv>
        </ParentDiv>
    )
}

export default SummaryView;