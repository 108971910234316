import React, { useEffect, useState } from "react";
import Header from "../common/header/index";
import { MainDiv, ParentDiv, WizardContent } from '../../../styles'
import WelcomeGreet from "../common/welcome-greet/welcome-greet";
import ProgressBar from "../common/progressBar/index";
import Loader from "../common/loader";
import ProductStatus from "../common/productsStatus/productStatus";
import WizardApp from "../common/scripts/wizard-app";
import config from "../../../config";


const IndexView = (props) => {
    const snippetStartApp = new WizardApp(config.appUrl);
    const [productDetails, setProductDetails] = useState({ status: "", productStatus: "", statusIcon: "", btnIcon: "", msgContent: "", eventChange: () => { } })
    const [isLoading, setLoader] = useState(true);
    const [userName, setUserName] = useState('');

    useEffect(() => {
        snippetStartApp.start()
            .then(() => {
                console.debug('Check if the product is installed either via PS/AF')
                return snippetStartApp.isExisting()
            }).then((isExisting) => {
                if (!isExisting) {
                    console.debug('Check for product availability in AF');
                    snippetStartApp.validateProductAvailability()
                        .then((isAvailable) => {
                            if (!isAvailable) {
                                setProductDetails({
                                    status: "fail", productStatus: "Product not available", statusIcon: "fas fa-times-circle",
                                    msgContent: "We're sorry but your Genesys Cloud org does not have the Snipped Recordings for Genesys Cloud app enabled. Please contact the authorities.",
                                })
                            } else {
                                setProductDetails({
                                    status: "success", productStatus: "Product Available", statusIcon: "fas fa-check-circle", btnIcon: "fas fa-chevron-right",
                                    msgContent: "We're ready to set you up. Please click the Start button.",
                                });
                            }
                            snippetStartApp.getUserDetails()
                                .then((user) => {
                                    if (user) {
                                        setUserName(user?.name)
                                    }
                                });
                            setLoader(false);
                        })
                    return Promise.resolve();
                }
                props.history.push('/help');
            }).catch((err) => {
                setLoader(false)
                setProductDetails({
                    status: "fail", productStatus: "Product could not be installed", statusIcon: "fas fa-exclamation-circle",
                    msgContent: "Error in installation.",
                })
                console.error('Error in index view: ' + err)
            })
    }, []);

    return (
        <ParentDiv>
            <div id="background"></div>
            <Header tail="Start"></Header>
            {(isLoading) ?
                <Loader></Loader> :
                <>
                    <WizardContent>
                        <WelcomeGreet userName={userName} tabName="start"></WelcomeGreet>
                    </WizardContent>

                    <ProgressBar tabName="start"></ProgressBar>

                    <MainDiv>
                        <ProductStatus {...productDetails} eventChange={() => props.history.push('/install')}></ProductStatus>
                    </MainDiv>
                </>
            }
        </ParentDiv>
    )
}

export default IndexView;