import React, { useEffect, Fragment } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { getParameterByName } from '../services/utils'
import config from '../config';
//import { validateSubscription } from '../services/utils'
import { getOrganization } from '../services/purecloud'
import { validateGCApp} from '../services/subscription';

const Main = () => {
    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        const purecloudInitialization = async () => {
            let env = new URLSearchParams(location.search).get('environment')
            console.log(new URLSearchParams(location.search).get('environment'))
            if (!env)
                env = 'mypurecloud.de'
            if(!sessionStorage.getItem('purecloud-csp-env'))
                sessionStorage.setItem('purecloud-csp-env', env)
            else
                env=sessionStorage.getItem('purecloud-csp-env')
            console.log('Main.purecloud.init:', env)

            if (getParameterByName('access_token')) sessionStorage.setItem('purecloud-csp-token', getParameterByName('access_token'))
            if (sessionStorage.getItem('purecloud-csp-token')) {
                try {
                    const organization = await getOrganization(sessionStorage.getItem('purecloud-csp-env'), sessionStorage.getItem('purecloud-csp-token'))
                    const response = await validateGCApp(sessionStorage.getItem('purecloud-csp-token'),sessionStorage.getItem('purecloud-csp-env') , organization.id, "snippet-recordings")
                    //await validateSubscription(organization.id, process.env.PRODUCT)
                    history.push('/home')
                } catch (error) {
                    history.push('/unauthorized', error.message)
                }
            }
            // change this to else history.push('/login')
            else {               
                history.push(`/login?environment=${env}`)
            }
        }
        const pureconnectInitialization = () => {
            if (sessionStorage.getItem('pureconnect-csrf-token')) {
                history.push('/home')
            } else {
                history.push('/login')
            }
        }
        switch (config.product) {
            case 'purecloud': purecloudInitialization()
                break;
            case 'pureconnect': pureconnectInitialization()
                break;
            case 'pureengage':
                break;
            default:
                break;
        }
        // eslint-disable-next-line
    }, [])

    return (<Fragment></Fragment>)
}

export default Main