import React, { Component } from "react";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import DoneRoundedIcon from "@material-ui/icons/DoneRounded";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import moment from "moment";
import { DateTimeRangePicker } from "./DateTimeRangePicker";
import "./SearchView.css";

class SearchView extends Component {
  constructor(props) {
    super(props);

    const today = moment(moment().format("YYYY-MM-DD 23:59"));
    const lastWeek = moment(moment(moment().format("YYYY-MM-DD")).subtract(7, "days"));
    this.state = {
      defaultFromTimeRange: lastWeek,
      defaultToTimeRange: today,
      fromTimeRange: lastWeek,
      toTimeRange: today,
      isValidTimeRange: true,
      callerNumber: "",
      conversationId: "",
      enabledParameterSearch: true,
      isValidConversationId: undefined,
      clearAgentSelection: false,
    };

    this.callerNumberRef = React.createRef();
    this.conversationIdRef = React.createRef();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
    this.handleClearForm = this.handleClearForm.bind(this);
    this.handleTimeRangeChange = this.handleTimeRangeChange.bind(this);
  }

  handleSubmitForm() {
    this.props.onSearchByCriteria({
      fromTimeRangeUTC: this.state.fromTimeRange.toDate().toISOString(),
      toTimeRangeUTC: this.state.toTimeRange.toDate().toISOString(),
      callerNumber: this.state.callerNumber,
      conversationId: this.state.conversationId,
    });
  }

  handleClearForm() {
    this.setState(() => ({
      fromTimeRange: this.state.defaultFromTimeRange,
      toTimeRange: this.state.defaultToTimeRange,
      isValidTimeRange: true,
      callerNumber: "",
      conversationId: "",
      isValidConversationId: undefined,
      enabledParameterSearch: true,
    }));
    this.props.onClearBtnClick({ clearButtonSelected: true });
  }

  handleTimeRangeChange(event) {
    const fromTimeRangeUTC = event.from.toDate().toISOString();
    const toTimeRangeUTC = event.to.toDate().toISOString();
    console.log(
      `Selected ${event.validRange ? "VALID" : "INVALID"} time range (UTC)`,
      `${fromTimeRangeUTC} until ${toTimeRangeUTC}`
    );
    this.setState(() => ({
      fromTimeRange: event.from,
      toTimeRange: event.to,
      isValidTimeRange: event.validRange,
      enabledParameterSearch:
        event.validRange === true 
    }));
  }

  handleChange(event) {
    if (event.target.name === "callerNumber") {
      this.setState(() => ({
        callerNumber: this.callerNumberRef.current.value,
      }));
    }
    if (event.target.name === "conversationId") {
      const newIsValidConversationId =
        this.conversationIdRef.current.value !== ""
          ? /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
              this.conversationIdRef.current.value
            )
          : undefined;

      this.setState(() => ({
        conversationId: this.conversationIdRef.current.value,
        isValidConversationId: newIsValidConversationId,
        enabledParameterSearch:
          this.state.isValidTimeRange === true,
      }));
    }
  }

  renderValidConversationId() {
    //console.log("IsValidConversationId", this.state.isValidConversationId);
    const tooltipCaption =
      this.state.isValidConversationId !== undefined
        ? this.state.isValidConversationId === true
          ? "Conversation ID is in the right format"
          : "Invalid format for Conversation ID"
        : "";
    return (
      tooltipCaption !== "" && (
        <Tooltip title={tooltipCaption}>
          {this.state.isValidConversationId === true ? (
            <DoneRoundedIcon className="valid-icon" />
          ) : (
            <WarningRoundedIcon className="warning-icon" />
          )}
        </Tooltip>
      )
    );
  }

  render() {
    return (
      <Accordion className="collapsibleSearchForm" defaultExpanded={true}>
        <AccordionSummary
          id="searchView"
          expandIcon={<ExpandMoreIcon />}
          className="accordionSummary"
        >
          {"Search"}
        </AccordionSummary>
        <AccordionDetails>
          <div>
              <div className="formInput">
                <div id="timeRange" className="inputField">
                  <label htmlFor="timeRange">
                    {"Time Range* :"}
                  </label>
                  &emsp;&nbsp;
                  <DateTimeRangePicker
                    fromTimeRange={this.state.fromTimeRange}
                    toTimeRange={this.state.toTimeRange}
                    onChange={this.handleTimeRangeChange}
                  />
                </div>
                <div className="inputField">
                  <label htmlFor="callerNumber">
                      {"Caller ID/ANI: "}
                  </label>
                  <input
                    ref={this.callerNumberRef}
                    name="callerNumber"
                    variant="outlined"
                    value={this.state.callerNumber}
                    onChange={this.handleChange}
                    style={{ height: "40px", width: "245px" }}
                  />
                </div>
                <div className="inputField">
                  <label htmlFor="conversationId">
                      {"Conversation ID: "}
                  </label>
                  <input
                    ref={this.conversationIdRef}
                    name="conversationId"
                    variant="outlined"
                    value={this.state.conversationId}
                    onChange={this.handleChange}
                    style={{ height: "40px", width: "245px" }}
                  />
                </div>
                
              </div>
            <div>
            &emsp;<Button
                id="findBtn"
                name="buttonSubmitForm"
                variant="contained"
                disabled={
                  !(
                    this.state.enabledParameterSearch 
                  )
                }
                onClick={this.handleSubmitForm}
                backgroundColor="#2a60c8"
                style = {{ textTransform:"none"}}
              >
                {"Find"}
              </Button>
              <Button
                id="clearBtn"
                name="buttonClearForm"
                variant="contained"
                color="white"
                onClick={this.handleClearForm}
                style = {{textTransform:"none"}}
              >
                {"Clear"}
              </Button>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default SearchView;
