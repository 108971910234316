import React, { useEffect, useState } from "react";
import { ChildDiv, MainDiv, ParentDiv, TitleDiv, WizardContent, olDiv, Button, SubDiv } from "../../../styles";
import Header from "../common/header";
import Loader from "../common/loader";
import ProgressBar from "../common/progressBar";
import WizardApp from "../common/scripts/wizard-app";
import WelcomeGreet from "../common/welcome-greet/welcome-greet";

const InstallView = (props) => {
    let [isLoading, setLoader] = useState(true);
    const startApp = new WizardApp();

    useEffect(() => {
        startApp.start();
        setLoader(false);
    }, [])

    const InstallConfiguration = () => {
        startApp.installConfigurations().then((done) => {
            props.history.push('/summary');
        }).catch((err) => {
            console.error(err)
            if (err && err.body && err.body.message) {
                props.history.push('/summary');
                window.localStorage.setItem("addOrError", err.body.message)
            }
            else {
                // props.history.push('/summary' + err);
                props.history.push('/summary');
                window.localStorage.setItem("addOrError", err)
            }
        });
    };

    return (
        <ParentDiv>
            <div id="background"></div>
            <Header tail="Install"></Header>

            <WizardContent>
                {(isLoading) &&
                    <Loader></Loader>
                }
                <WelcomeGreet tabTitle="Install"></WelcomeGreet>
            </WizardContent>

            <ProgressBar tabName="install"></ProgressBar>

            <MainDiv>
                <SubDiv>As part of this installation request, we will be executing below tasks.</SubDiv>
                <ChildDiv type="install">
                    <TitleDiv>Create Roles</TitleDiv>
                    <span>Admin, Supervisor & Agent roles to provide role based access to the app.</span>
                    <span>A role required to access org by application backend.</span>
                </ChildDiv>
                <ChildDiv type="install">
                    <TitleDiv>Create OAuth Client</TitleDiv>
                    <span>Create OAuth Client for server-to-server communication.</span>
                </ChildDiv>
                <Button position="right" onClick={InstallConfiguration}>
                    <span>Submit</span>
                    <i className="fa-solid fa-circle-plus"></i>
                </Button>
            </MainDiv>
        </ParentDiv >
    )
}

export default InstallView;