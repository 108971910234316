const utils = require("./utils")
export const fetchWrapper = async (url, init) => {
    const response = await fetch(url, init)
    let correlationId
    for (let pair of response.headers.entries()) {
        if (pair[0] === 'inin-correlation-id') {
            correlationId = pair[1]
            break
        }
    }
    const json = await response.json()
    return response.ok
        ? { ...json, correlationId }
        : Promise.reject({ ...json, correlationId })
}

export const isLoggedIn = () => {
    console.log('getting token', sessionStorage.getItem('purecloud-csp-token'))
    return sessionStorage.getItem('purecloud-csp-token') !== null
}

export const getOrganization = (environment, token) => {
    console.log(`getOrganization.env:${environment}, token:${token}`)
    return fetchWrapper(`https://api.${environment}/api/v2/organizations/me`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${token}`
        }
    })
}

export const getAllDatatables = async (environment, token, pageSize) => {
    console.log(`getAllDatatables.env:${environment}, token:${token}`)
    try{
        let allDatatablesRetrieved = false;
        let pageNumber = 1;
        let DatatableResult = [];
        let DatatableList = [];
        while (!allDatatablesRetrieved) {
            try {
            const getDatatableResponse = await getDatatables(
                environment,
                token,
                pageSize,
                pageNumber
            );
            if (getDatatableResponse &&
                getDatatableResponse.entities &&
                getDatatableResponse.entities.length > 0
            ) {
                // DatatableResult = DatatableResult.concat(getDatatableResponse.entities);
                DatatableResult = [...getDatatableResponse.entities];
                pageNumber++;
                console.log(DatatableResult)
            } else allDatatablesRetrieved = true;
            } catch (error) {
            if (error.status === 429) {
                await utils.sleep(error.retryAfterMs);
            } else throw error;
            }
        }
        DatatableResult.forEach((Datatable) => {
            DatatableList.push({ id: Datatable.id, name: Datatable.name });
          });
        return DatatableList
    }
    catch(err)
    {
        console.error(err)
    }
}

const getDatatables = (environment, token, pageSize, pageNumber) =>{
    return fetchWrapper(
        `https://api.${environment}/api/v2/flows/datatables/divisionviews?pageSize=${pageSize}&pageNumber=${pageNumber}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        }
      );
}

export const getDatatableRows = (environment, token, datatableId) => {
    console.log(`getDatatableRows.env:${environment}, token:${token}`)
    return fetchWrapper(`https://api.${environment}/api/v2/flows/datatables/${datatableId}/rows?pageSize=100&showbrief=false`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${token}`
        }
    })
}



export default {
    isLoggedIn,
    fetchWrapper,
    getOrganization,
    getAllDatatables,
    getDatatableRows
}