import styled from 'styled-components';

export const ParentDiv = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`

export const WizardContent = styled.div`
    flex: 1 0 auto !important;
    font-size: 14.4px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 20px;
`

export const MainDiv = styled.div`
    margin: auto;
    padding: 20px 15px 20px 15px;
    margin-top: 20px;
    width: 79.5%;
    min-width: 800px;
    text-align: center;
    border: 1px solid #CBCBCB;
    background: #FFF;
    overflow: hidden;
    border-radius: 4px;
`
export const SubDiv = styled.div`
    margin: auto;
    padding: 5px;
    margin-top:5px;
    width: 74.5%;
    min-width: 800px;
    text-align: left;
    background: #FFF;
    overflow: hidden;
    border-radius: 4px;
    `
export const Button = styled.button`
    border-radius: 5px 5px;
    padding: 10px 30px;
    text-align: center;
    font-weight: 600;
    font-size: 1em;
    cursor: pointer;
    background-color: #ff4f1f;
    color: white;
    border: 1px solid #e1727d;
    float: ${props => props.position === "right" ? 'right' : 'center'};
    margin-top : ${props => props.position === "right" ? '10px' : '15px'};

    &:hover{
        background-color: #ed7e60;
    }
`

export const TitleDiv = styled.div`
    padding: 7px;
    text-align: initial;
    background: #ff4f1f;
    color: white;
    font-size: 15px;    
    font-weight: 600;
`

export const ConfigTitle = styled.div`
    padding: 16px;
    text-align: initial;
    color: #172B52;
    text-decoration: underline;
    font-size: 27px;
    font-weight: 600;
    border-radius: 3px;
    text-align: center;
}
`

export const ChildDiv = styled.div`
    border: ${props => (props.type === "install" || props.type === "list") ? '1px solid #6c757d75' : ''};
    min-height: ${props => props.type === "install" ? '105px' : '150px'};
    width: 75%;
    min-width: 70%;
    max-width: 80%;
    margin: auto;
    border-radius:4px;
    margin-top:${props => (props.type === "install" || props.type === "list") ? '10px' : ''};
    & span {
    margin: ${props => props.type === "install" ? '10px 25px' : ''};
    display: flex;
}
`
export const SuccessSummary = styled.p`
    white-space: pre;
    margin: 10px;
    text-align: left;
    `