import './Home.css'
import React from 'react'
import { Container } from 'reactstrap'
import Header from '../header/Header';
import SearchView from '../search/SearchView';
import { ResultView } from "../results/ResultView";
import { PlaybackView } from '../playback/PlaybackView';
import backend from "../../services/backend";
import { WaitingModal } from "../modals/WaitingModal";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import moment from "moment";
export default function Home(props) {
  const [searchParams, setSearchParams] = React.useState(undefined);
  const [totalHits, setTotalHits] = React.useState(undefined);
  const [selectedConversation, setSelectedConversation] = React.useState(undefined);
  const [conversationData, setConversationData] = React.useState([]);
  const [noResultsMessage, setNoResultsMessage] = React.useState(undefined);
  const [isResultsFetched, setIsResultsFetched] = React.useState(false);
  const [waitingModalHeader, setWaitingModalHeader] = React.useState('')
  const [fetchError, setFetchError] = React.useState(undefined)

  const handleCriteriaSearch = async (params) => {
    try {
      const DATETIME_FORMAT = "MM/DD/YYYY, hh:mm A";
      setSearchParams(params);
      setConversationData([])
      setSelectedConversation(undefined)
      setIsResultsFetched(true)
      setWaitingModalHeader('Fetching Search Results')
      const token = sessionStorage.getItem('purecloud-csp-token')
      const env = sessionStorage.getItem('purecloud-csp-env')
      const response = await backend.getConversationDetails(token, env, params.fromTimeRangeUTC, params.toTimeRangeUTC, params.callerNumber, params.conversationId)

      //to get duration, Start time in desired format inside result table
      let modifiedResponse = response.results
      modifiedResponse.forEach(element => {
        element.yearweek = element.id
        element.id = element.recordingID
        element.conversationStart = new Date(moment(element.conversationStart).format(DATETIME_FORMAT))
        element.durationInMS = (moment(element.conversationEnd).diff(moment(element.conversationStart)))
        element.duration = msToHMS((moment(element.conversationEnd).diff(moment(element.conversationStart))))
        if(element.queueName === null)
          element.queueName = "NA"
      });


      if (response.results.length > 0) {
        setConversationData(modifiedResponse)
        setTotalHits(response.results.length)
        setNoResultsMessage(undefined)
      }
      else {
        setConversationData([]);
        setTotalHits(undefined);
        setNoResultsMessage("No results found");
      }
      setIsResultsFetched(false)
    }
    catch {
      setFetchError("Failed to fetch results. Please try again!");
      setIsResultsFetched(false)
    }
  };

  const msToHMS = (ms) => {
    let seconds = ms / 1000;
    // 2- Extract hours:
    const hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
    seconds = seconds % 3600; // seconds remaining after extracting hours
    // 3- Extract minutes:
    const minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
    // 4- Keep only seconds not extracted to minutes:
    seconds = seconds % 60;
    return (hours > 0 ? (hours + "h " + minutes + "m " + Math.round(seconds) + "s") : ((" " + minutes + "m " + Math.round(seconds) + "s")));
  }
  const handleClearBtnClick = (clearBtn) => {
    setTotalHits(0);
    setSelectedConversation("");
  };

  const handleConversationSelect = (newConversation) => {
    setSelectedConversation(newConversation);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFetchError(undefined);
  };

  return (

    <Container>
      <Header />
      <WaitingModal
        isOpen={isResultsFetched}
        header={waitingModalHeader}
      />
      <Snackbar
        open={fetchError !== undefined}
        autoHideDuration={5000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {fetchError}
        </Alert>
      </Snackbar>
      <SearchView
        onSearchByCriteria={handleCriteriaSearch}
        onClearBtnClick={handleClearBtnClick}
      />
      <ResultView
        conversationData={conversationData}
        totalHits={totalHits}
        noResultsMessage={noResultsMessage}
        onConversationSelect={handleConversationSelect}
      /> <br />
      <PlaybackView
        conversation={selectedConversation}
      />
    </Container>


  )

}