import React from "react";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Backdrop from "@material-ui/core/Backdrop";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import CopyIcon from "@material-ui/icons/FileCopy";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from '@mui/material/Tooltip'
import { RadialLoader } from "./RadialLoader";
import "./PlaybackView.css";
import Waveform from "./Waveform";
import ReactAudioPlayer from 'react-audio-player';
import CallIcon from '@material-ui/icons/Call';
import backend from "../../services/backend";
import DownloadLink from "react-download-link";
import { WaitingModal } from "../modals/WaitingModal";
import AudioPlayer from 'react-h5-audio-player';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled'
import DeleteIcon from '@mui/icons-material/Delete'
import utils from "../../services/utils";
import { triggerIncident } from '../../services/pagerduty';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
});

const AudioPlayerDialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const AudioPlayerDialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export const PlaybackView = withStyles(styles)((props) => {
  const { classes } = props;
  const DATETIME_FORMAT = "DD-MM-YYYY HH:mm";
  const [audioOpen, setAudioOpen] = React.useState(false);
  // const [videoOpen, setVideoOpen] = React.useState(false);
  const [audioFetch, setAudioFetch] = React.useState(false);
  const [snippetRecordingFile, setSnippetRecordingFile] = React.useState(undefined);
  const [audioFetchError, setAudioFetchError] = React.useState(undefined);
  const [seekTo, setSeekTo] = React.useState(undefined);
  const [isCopied, setIsCopied] = React.useState(false);
  const [isUriFetched, setIsUriFetched] = React.useState(false)
  const [waitingModalHeader, setWaitingModalHeader] = React.useState('')
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = React.useState(false)
  const [deleteSuccess, setDeleteSuccess] = React.useState(false)
  const handleAudioOpen = async () => {
    if (props.conversation) {
      try {
        const token = sessionStorage.getItem('purecloud-csp-token')
        const env = sessionStorage.getItem('purecloud-csp-env')
        setIsUriFetched(true)
        setWaitingModalHeader("Fetching playback audio")
        const response = await backend.getSignatureURL(token, env, props.conversation)
        if(response.signedUrl != undefined){
        setAudioOpen(true);
        let blob = response.signedUrl
        setSnippetRecordingFile(blob)
        setIsUriFetched(false)
        }
        else{
          setAudioFetchError("Recording is not available. Failed to fetch!");
          setIsUriFetched(false)
        }
      } catch (ex) {
        setAudioFetchError("Failed to fetch. Please try again!");
        fetchPagerDuty("Failed to fetch the recording audio");
        setIsUriFetched(false)
      }
    }
  };

  const handleAudioClose = () => {
    setAudioOpen(false);
  };

  const handleDownload = async () => {
    if (props.conversation) {
      try {
        const token = sessionStorage.getItem('purecloud-csp-token')
        const env = sessionStorage.getItem('purecloud-csp-env')
        setIsUriFetched(true)
        setWaitingModalHeader("Fetching playback audio")
        const response = await backend.getSignatureURL(token, env, props.conversation)
        if(response.signedUrl != undefined){
          setSnippetRecordingFile(response.signedUrl)
          var uri = response.signedUrl
          var link = document.createElement('a');
          link.href = uri;
          link.download = "test.wav"
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setIsUriFetched(false)
        }
        else{
          setAudioFetchError("Recording is not available. Failed to Download");
          setIsUriFetched(false)
        }
      }
      catch (err) {
        setAudioFetchError("Failed to Download. Please try again!");
        fetchPagerDuty("Failed to download the recording audio");
        setIsUriFetched(false)
      }
    }
  };

  const handleDelete = async () => {
    if (props.conversation) {
      try {
        console.log("Playback View: Deleting Recording")
        const token = sessionStorage.getItem('purecloud-csp-token')
        const env = sessionStorage.getItem('purecloud-csp-env')
        setOpenDeleteConfirmation(false)
        setIsUriFetched(true)
        setWaitingModalHeader("Deleting recording")
        await backend.deleteRecording(token, env, props.conversation)
        setIsUriFetched(false)
        hasConversationData.recordingStatus = "Deleted"
        setDeleteSuccess(true)
      }
      catch (err) {
        setAudioFetchError("Failed to Delete recording.Please try again!");
        fetchPagerDuty("Failed to delete the recording audio");
        setIsUriFetched(false)
        setOpenDeleteConfirmation(false)
      }
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAudioFetchError(undefined);
    setDeleteSuccess(false);
  };

  const handleCloseDeleteConfirmation = () => {
    setOpenDeleteConfirmation(false);
  };

  const openDeleteDialog = () => { setOpenDeleteConfirmation(true)};

  const handleAudioError = () => {
    setAudioOpen(false)
    setAudioFetchError("Failed to fetch audio. Please try again!");
  }

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const getTimelineData = () => {
    const data = [];

    if (props.conversation) {
      // Fixed data definitions
      data.push([
        { type: "string", id: "ConversationId" },
        { type: "string", id: "Agent" },
        { type: "date", id: "Start" },
        { type: "date", id: "End" },
      ]);

      // Add "marker" records to explicitly indicate conversation Start and End
      const conversationStart = moment(
        props.conversation.conversationStart
      ).toDate();
      data.push([undefined, "Start", conversationStart, conversationStart]);
      const conversationEnd = moment(
        props.conversation.conversationEnd
      ).toDate();
      data.push([undefined, "End", conversationEnd, conversationEnd]);

      if (props.conversation.agentSegments) {
        props.conversation.agentSegments.forEach((segment) => {
          data.push([
            undefined,
            segment.agentName,
            moment(segment.start).toDate(),
            moment(segment.end).toDate(),
          ]);
        });
      }
    }

    return data;
  };

  const fetchPagerDuty = async (msg) => {
    const status = 500;
    const error = {
      exception: 'Failure in Playback View',
        message: msg
    };
    const payload = {
        summary: "Error - pager duty in a Snippet Recordings WebApp",
        severity: 'critical',
        source: 'React App - PlaybackView Component',
        routing_key: process.env.PAGER_DUTY_SERVICE_WEBAPP_INTEGRATION_KEY,
        status,
        ...error
    };
    // use this below if statement as an example wrapper to all pager duty events, we should not be sending pager duty events for dev, test, and sandbox. Only for PROD.
    if ((process.env.NODE_ENV === 'prod' || process.env.PAGER_DUTY_DEVELOPMENT_INCIDENT_CREATION === 'on') && (status === 403 || status >= 500)) { 
        try {
            const { data } = await triggerIncident(payload);
        } catch ({ data, status, success }) {
            console.log(`Data: ${JSON.stringify(data)}, Status: ${JSON.stringify(status)}`);
        }
    }
    
};

  console.log("Props", props);
  const hasConversationData = props.conversation;

  const timelineData = getTimelineData();

  console.log("TimelineData", timelineData);

  return (
    <Container className="playbackContainer" component={Paper}>
      {/* <WaitingModal
                isOpen={isUriFetched}
                header={waitingModalHeader}
            /> */}
      <Backdrop className={classes.backdrop} open={isUriFetched}>
        <RadialLoader />
        <div className="backdropMessage">
          {"Please wait... we're processing your request!"}
        </div>
      </Backdrop>
      <Snackbar
        open={audioFetchError !== undefined}
        autoHideDuration={5000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {audioFetchError}
        </Alert>
      </Snackbar>
      <Dialog
        open={openDeleteConfirmation}
        onClose={handleCloseDeleteConfirmation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Recording?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the recording?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style = {{ textTransform:"none"}} onClick={handleCloseDeleteConfirmation}>
            No
          </Button>
          <Button variant="contained" style = {{ textTransform:"none"}} onClick={handleDelete} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={deleteSuccess}
        autoHideDuration={5000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="success">
          {'Recording deleted successfully'}
        </Alert>
      </Snackbar>
      <div id="interactionDetails">
        {hasConversationData && (
          <div>
            <br />
            <CallIcon />
            <span style={{ fontWeight: "bold" }}>
              {"  Conversation Details"} &nbsp;&nbsp;
            </span>
            <hr />
            <TableContainer className="resultContainer" component={Paper}>
              <Table size="small" stickyHeader>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {/* Create another table within this cell*/}
                      <Table size="small" stickyHeader>
                        <TableBody>
                          <TableRow >
                            <TableCell style={{ borderBottom: "none" }}>
                              <span style={{ fontWeight: "bold" }}>
                                {"Conversation ID"} &nbsp;&nbsp;
                              </span>
                            </TableCell>
                            <TableCell style={{ borderBottom: "none" }}>
                              <span>{hasConversationData.conversationId} </span>
                              <CopyToClipboard
                                text={hasConversationData.conversationId}
                                onCopy={onCopyText}
                              >
                                <span>{isCopied ? "Copied!" : <CopyIcon />}</span>
                              </CopyToClipboard>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ borderBottom: "none" }}>
                              <span style={{ fontWeight: "bold" }}>
                                {"Recording ID"} &nbsp;&nbsp;
                              </span>
                            </TableCell>
                            <TableCell style={{ borderBottom: "none" }}>
                              <span>{hasConversationData.recordingID} </span>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ borderBottom: "none" }}>
                              <span style={{ fontWeight: "bold" }}>
                                {"Internal Participant(s)"} &nbsp;&nbsp;
                              </span>
                            </TableCell>
                            <TableCell style={{ borderBottom: "none" }}>
                              <span>{hasConversationData.agentName} </span>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ borderBottom: "none" }}>
                              <span style={{ fontWeight: "bold" }}>
                                {"External Participant(s)"} &nbsp;&nbsp;
                              </span>
                            </TableCell>
                            <TableCell style={{ borderBottom: "none" }}>
                              <span>{hasConversationData.callerId} </span>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ borderBottom: "none" }}>
                              <span style={{ fontWeight: "bold" }}>
                                {"Start Time"} &nbsp;&nbsp;
                              </span>
                            </TableCell>
                            <TableCell style={{ borderBottom: "none" }}>
                              <span>{moment(hasConversationData.conversationStart).format(DATETIME_FORMAT)} </span>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ borderBottom: "none" }}>
                              <span style={{ fontWeight: "bold" }}>
                                {"End Time"} &nbsp;&nbsp;
                              </span>
                            </TableCell>
                            <TableCell style={{ borderBottom: "none" }}>
                              <span>{moment(hasConversationData.conversationEnd).format(DATETIME_FORMAT)} </span>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ borderBottom: "none" }}>
                              <span style={{ fontWeight: "bold" }}>
                                {"Direction"} &nbsp;&nbsp;
                              </span>
                            </TableCell>
                            <TableCell style={{ borderBottom: "none" }}>
                              <span>{hasConversationData.originatingDirection} </span>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ borderBottom: "none" }}>
                              <span style={{ fontWeight: "bold" }}>
                                {"Queue"} &nbsp;&nbsp;
                              </span>
                            </TableCell>
                            <TableCell style={{ borderBottom: "none" }}>
                              <span>{hasConversationData.queueName} </span>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ borderBottom: "none" }}>
                              <span style={{ fontWeight: "bold" }}>
                                {"Recording Status"} &nbsp;&nbsp;
                              </span>
                            </TableCell>
                            <TableCell style={{ borderBottom: "none" }}>
                              <span>{hasConversationData.recordingStatus} </span>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ borderBottom: "none" }}>
                              <span style={{ fontWeight: "bold" }}>
                                {"Retention"} &nbsp;&nbsp;
                              </span>
                            </TableCell>
                            <TableCell style={{ borderBottom: "none" }}>
                              <span>{hasConversationData.retention} </span>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ borderBottom: "none" }}>
                              <span style={{ fontWeight: "bold" }}>
                                {"WrapUp Code"} &nbsp;&nbsp;
                              </span>
                            </TableCell>
                            <TableCell style={{ borderBottom: "none" }}>
                              <span>{hasConversationData.wrapUpCode} </span>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      <br />

                    </TableCell>
                    <TableCell>
                      <IconButton
                        variant="contained"
                        disabled={hasConversationData.recordingStatus === "Purged" || hasConversationData.recordingStatus === "Deleted"}
                        style={{ textTransform: "none" }}
                        // startIcon={
                        //   <IconAudio
                        //     fill="white"
                        //     altCaption="Play snippet recording (audio)"
                        //     width={50}
                        //     height={50}
                        //   />
                        // }
                        onClick={handleAudioOpen}
                      >
                        {
                                hasConversationData.recordingStatus !== "Available" && (
                                  <PlayCircleFilledIcon
                                  fill="white"
                                  altCaption="Play snippet recording (audio)"
                                  style={{ fontSize: 80, color: "gray" }}
                                />

                                )

                        }
                        {
                                hasConversationData.recordingStatus === "Available" && (
                        <Tooltip title="Play Recording">
                          <PlayCircleFilledIcon
                            fill="white"
                            altCaption="Play snippet recording (audio)"
                            style={{ fontSize: 80, color: "#2a60c8" }}
                          />
                        </Tooltip>
                        )

                      }
                      </IconButton>
                      <IconButton
                        variant="contained"
                        disabled={hasConversationData.recordingStatus === "Purged" || hasConversationData.recordingStatus === "Deleted"}
                        style={{ textTransform: "none" }}
                        onClick={handleDownload}
                      >
                        {
                                hasConversationData.recordingStatus !== "Available" &&  (
                                  <DownloadForOfflineIcon
                            fill="white"
                            altCaption="Download snippet recording (audio)"
                            style={{ fontSize: 80, color: "gray" }}
                          />

                                )

                        }
                        {
                                hasConversationData.recordingStatus === "Available" && (
                        <Tooltip title="Download Recording">
                          <DownloadForOfflineIcon
                            fill="white"
                            altCaption="Download snippet recording (audio)"
                            style={{ fontSize: 80, color: "#2a60c8" }}
                          />
                        </Tooltip>
                        )

                      }
                      </IconButton>
                      <IconButton
                        variant="contained"
                        disabled={hasConversationData.recordingStatus === "Purged" || hasConversationData.recordingStatus === "Deleted"}
                        style={{ textTransform: "none" }}
                        onClick={openDeleteDialog}
                      >
                        {
                                hasConversationData.recordingStatus !== "Available" && (
                                  <DeleteIcon
                            fill="white"
                            altCaption="Delete snippet recording (audio)"
                            style={{ fontSize: 80, color: "gray" }}
                          />

                                )

                        }
                        {
                                hasConversationData.recordingStatus === "Available" && (
                        <Tooltip title="Delete Recording">
                          <DeleteIcon
                            fill="white"
                            altCaption="Delete snippet recording (audio)"
                            style={{ fontSize: 80, color: "#2a60c8" }}
                          />
                        </Tooltip>
                        )

                      }
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <br />
          </div>
        )}
      </div>

      <div className="playbackButtons">
        <Dialog
          className="audioPlayerDialog"
          onClose={handleAudioClose}
          open={audioOpen}
          onBackdropClick="false"
        >
          <div >
            <AudioPlayerDialogTitle onClose={handleAudioClose} >Audio Player</AudioPlayerDialogTitle>
            <AudioPlayerDialogContent >
              <ReactAudioPlayer
                className="audioplayer"
                src={snippetRecordingFile}
                autoPlay
                controlsList="nodownload noplaybackrate"
                controls
                onError={handleAudioError}
              />


              {/* <Waveform url={snippetRecordingFile}/>  */}


            </AudioPlayerDialogContent>
          </div>
        </Dialog>
      </div>
    </Container>
  );
});
