import React from "react";
import styled from "styled-components";

const LoaderDiv = styled.div`
    margin-top: 30px;
    text-align: center;
`
const Icon = styled.i`
    font-size: 70px;
`

const Loader = () => {
    return (
        <LoaderDiv>
            <Icon className="fa fa-spinner fa-spin"></Icon>
        </LoaderDiv>
    )
}

export default Loader;