import React from "react";
import { Button, SuccessSummary } from "../../../../styles";
import { Status, Icon } from "../../Index/index-style";

const ProductStatus = (props) => {
    const { status, productStatus, statusIcon, btnIcon, msgContent, contentSize, eventChange } = props;
    return (
        <div>
            <Status isSuccess={status}>
                <span>{productStatus}</span>
                <Icon className={statusIcon}></Icon>
            </Status>
            {contentSize === "large" ? 
                 <SuccessSummary>{msgContent}</SuccessSummary>
                : <p><span>{msgContent}</span></p> }
            
            {status === "success" && btnIcon &&
                <>
                    <Button position="right" onClick={() => eventChange('configuration')}>
                        <span>Start </span>
                        <i className={`button-icon ${btnIcon}`}></i>
                    </Button>
                    
                </>
            }
        </div>
    )
}

export default ProductStatus