import config from '../config'

export const getParameterByName = (name) => {
    // eslint-disable-next-line
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]")
    const regex = new RegExp("[\\#&]" + name + "=([^&#]*)"),
        results = regex.exec(window.location.hash)
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export const fetchWrapper = async (url, init) => {
    const response = await fetch(url, init)
    const json = await response.json()
    return response.ok ? json : Promise.reject(json)
}

export const validateSubscription = (customerId, applicationId) => {
    return fetchWrapper(config.endpoints.subscription, {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({ customerId, applicationId })
    })
}
export const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export default {
    getParameterByName,
    fetchWrapper,
    validateSubscription,
    sleep
}