import React, { Fragment, useEffect } from 'react';
import Button from "@material-ui/core/Button";
import config from '../config'
import utils from '../services/utils'
import CircularProgress from '@mui/material/CircularProgress';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import Alert from '@mui/material/Alert';
import Snackbar from "@material-ui/core/Snackbar";
import backend from "../services/backend";
import { AppBar } from '@material-ui/core';
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import "./AgentDesktopView.css";
import { local } from "./language.js"
import { useLocation, useHistory } from 'react-router-dom'

let params, filterValue = null;
let platformClient = window.require('platformClient');
let client = platformClient.ApiClient.instance;
const uapi = new platformClient.UsersApi()
const napi = new platformClient.NotificationsApi()
let notificationFlag = true


const AgentDesktopView = () => {
    let gc_region = config.region
    let debug = true
    let language = ''
    const [filterByValue, setFilterByValue] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const [buttonLabel, setButtonLabel] = React.useState("Start")
    const [statusMessage, setStatusMessage] = React.useState("")
   // const [disableButton, setDisableButton] = React.useState(true)
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [alertOpen1, setAlertOpen1] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("")
    const [disButton, setDisButton] = React.useState(true)
    const [attempt, setAttempt] = React.useState(0)
    const [retentionList, setRetentionList] = React.useState([{RetentionDays: 365, key: 'default'}]);
    //let retentionList = [{RetentionDays: 365, key: 'default'}];
    const history = useHistory()
    let pairs =''
    let env = '', convId = '', participantId = '', agent = '', queue = '', ret = ''
    useEffect(() => {
        params = new URLSearchParams(window.location.search);
        let queryString = window.location.search.substring(1)
        pairs = queryString.split('&')
        console.log("QueryParameters: ", pairs)
        
        for (let i = 0; i < pairs.length; i++) {
            const currentParam = pairs[i].split('=')
            if (currentParam[0] === 'environment')
                env = currentParam[1]
            if (currentParam[0] === 'conversationId')
                convId = currentParam[1]
            if (currentParam[0] === 'participantId')
                participantId = currentParam[1]
            if (currentParam[0] === 'agentName')
                agent = currentParam[1].replace(/%20/g, " ")
            if (currentParam[0] === 'queueName')
                queue = currentParam[1].replace(/%20/g, " ")
            if (currentParam[0] === 'language')
                language = currentParam[1]
            if (currentParam[0] === 'retention')
                ret = currentParam[1]
        }
        if(ret === '')
            setDisButton(true)
        else
            setDisButton(false)
        if (utils.getParameterByName("access_token")) {
            sessionStorage.setItem(
                "agent-snippet-recording-token",
                utils.getParameterByName("access_token")
            );
        }
        if (env === '')
            env = sessionStorage.getItem('agent-snippet-recording-env')
        else
            sessionStorage.setItem('agent-snippet-recording-env', env)
        if (convId === '')
            convId = sessionStorage.getItem('agent-snippet-recording-convId')
        else
            sessionStorage.setItem('agent-snippet-recording-convId', convId)
        if (participantId === '')
            participantId = sessionStorage.getItem('agent-snippet-recording-participantId')
        else
            sessionStorage.setItem('agent-snippet-recording-participantId', participantId)
        if (agent === '')
            agent = sessionStorage.getItem('agent-snippet-recording-agent')
        else
            sessionStorage.setItem('agent-snippet-recording-agent', agent)
        if (queue === '')
            queue = sessionStorage.getItem('agent-snippet-recording-queue')
        else
            sessionStorage.setItem('agent-snippet-recording-queue', queue)
        if (language === '')
            language = sessionStorage.getItem('agent-snippet-recording-language')
        else
            sessionStorage.setItem('agent-snippet-recording-language', language)

        if (!sessionStorage.getItem('agent-snippet-recording-token')) {
            const queryStringData = {
                response_type: 'token',
                client_id: config.purecloud.clientId,
                redirect_uri: `${window.location.protocol}//${window.location.host}/agentdesktop`
            }
            const param = Object.keys(queryStringData).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(queryStringData[k])}`).join('&')
            console.log(`Authorization request: ${param}`)
            //window.location = `https://login.${sessionStorage.getItem('agent-snippet-recording-env')}/oauth/authorize?${param}`
            history.push(`/login?environment=${env}&usePopupAuth=true&redirecturl=agentdesktop`)
        }

        // if(sessionStorage.getItem('agent-snippet-recording-button') === 'Start')
        //     setButtonLabel('Stop')
        
        wss()

    })

    async function wss() {
        try {
            if(attempt == 0)
            {
                await backend.getMissedRecordings(sessionStorage.getItem('agent-snippet-recording-token'), sessionStorage.getItem('agent-snippet-recording-env'))
                setAttempt(1)
            }
            client.setAccessToken(sessionStorage.getItem('agent-snippet-recording-token'));
            config.region === 'mypurecloud.de' ? client.setEnvironment(platformClient.PureCloudRegionHosts.eu_central_1) : client.setEnvironment(platformClient.PureCloudRegionHosts.us_east_1)
            var user = await uapi.getUsersMe()
            //Need to store wss as only can have 15 per agent. Also bad practice to create multiply
            if (sessionStorage.getItem("gc_channelid")) {
                if (debug) console.info('channelid already exists...')
                if (debug) console.log(sessionStorage.getItem("gc_channelid"))
                var channelid = sessionStorage.getItem("gc_channelid")

                let userCallsTopic = `v2.users.${user.id}.conversations.calls`
                await napi.postNotificationsChannelSubscriptions(channelid, [{
                    id: userCallsTopic
                }])
                if (debug) console.info(`Subscribed to topic ${userCallsTopic}`)
            }
            if (!sessionStorage.getItem("gc_channelid")) {
                let channel = await napi.postNotificationsChannels()
                let userCallsTopic = `v2.users.${user.id}.conversations.calls`
                await napi.postNotificationsChannelSubscriptions(channel.id, [{
                    id: userCallsTopic
                }])
                if (debug) console.info(`Subscribed to topic ${userCallsTopic}`)
                sessionStorage.setItem("gc_channelid", channel.id)
                if (debug) console.log(sessionStorage.getItem("gc_channelid"))
            }
        } catch (err) {
            console.error('Setup WSS error: ', err)
            sessionStorage.removeItem("gc_channelid")
        }

        //Create websocket for events
        try {
            let socket = new WebSocket(`wss://streaming.${gc_region}/channels/${sessionStorage.getItem("gc_channelid")}`)

            socket.onmessage = async function (event) {
                let details = JSON.parse(event.data)

                //if Call notification
                if (details.topicName === `v2.users.${user.id}.conversations.calls`) {
                    console.log('Call Notification: ', details)
                    let participant = details.eventBody.participants.slice().reverse().find(p => p.purpose === "agent" && p.id === sessionStorage.getItem('agent-snippet-recording-participantId'))
                    //on agent disconnect or terminate
                    if (participant) {
                        if ((participant.state === 'disconnected' || participant.state === 'terminated') && buttonLabel === "Stop") {
                            if (notificationFlag === true) {
                                //freeze the button in UI to stop agent actions after call disconnect
                                sessionStorage.setItem('agent-snippet-recording-button', 'Stop')
                                setButtonLabel("Start")
                                notificationFlag = false
                                setDisButton(true)
                                console.log('participant: ', participant)
                                // logic to call triggerRecording lambda with recording action stop
                                let defaultRetention;
                                console.log("Rention value:", filterByValue, filterValue)
                                defaultRetention = ret === '' ? config.defaultRetentionPeriod : ret;
                                console.log("Default retention:", defaultRetention)
                                await backend.recordingActions(
                                    sessionStorage.getItem('agent-snippet-recording-token'),
                                    sessionStorage.getItem('agent-snippet-recording-env'),
                                    sessionStorage.getItem('agent-snippet-recording-convId'),
                                    sessionStorage.getItem('agent-snippet-recording-participantId'),
                                    "stop",
                                    sessionStorage.getItem('agent-snippet-recording-agent'),
                                    sessionStorage.getItem('agent-snippet-recording-queue'),
                                    defaultRetention

                                )
                                //disable all UI elements
                            }

                        }

                    }
                }
            }
            if (debug) console.log(`Waiting for events on wss://streaming.${gc_region}/channels/${sessionStorage.getItem("gc_channelid")}`)
        } catch (err) {
            console.error('Websocket error: ', err)
            sessionStorage.removeItem("gc_channelid")
        }
        
    }
    const handleAlertClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setAlertOpen(false);
        setAlertOpen1(false)
    };

    const handleButtonClick = async () => {
        try {
            if (buttonLabel === "Start") {
                sessionStorage.setItem('agent-snippet-recording-button', 'Start')
                let retentionPeriod;
                console.log("start")
                setDisButton(true)
                setLoading(true)
                for (let i = 0; i < pairs.length; i++) {
                    const currentParam = pairs[i].split('=')
                    if (currentParam[0] === 'retention')
                     {   console.log('ret valu',currentParam[1]);
                        retentionPeriod =currentParam[1] }
                }
                await backend.recordingActions(
                    sessionStorage.getItem('agent-snippet-recording-token'),
                    sessionStorage.getItem('agent-snippet-recording-env'),
                    sessionStorage.getItem('agent-snippet-recording-convId'),
                    sessionStorage.getItem('agent-snippet-recording-participantId'),
                    "start",
                    sessionStorage.getItem('agent-snippet-recording-agent'),
                    sessionStorage.getItem('agent-snippet-recording-queue'),
                    retentionPeriod
                )
                // await utils.sleep(2000)
                setLoading(false)
                setDisButton(false)
                setButtonLabel("Stop")
                setAlertOpen(true)
                setStatusMessage(local[sessionStorage.getItem('agent-snippet-recording-language')].startMessage)
                // for (let i = 0; i < pairs.length; i++) {
                //     const currentParam = pairs[i].split('=')
                //     if (currentParam[0] === 'retention')
                //      {   console.log('ret valu',currentParam[1]);
                //     }
                // }
            }
            else {
                sessionStorage.setItem('agent-snippet-recording-button', 'Stop')
                let retentionPeriod;
                console.log("stop")
                setLoading(true)
                setDisButton(true)
                for (let i = 0; i < pairs.length; i++) {
                    const currentParam = pairs[i].split('=')
                    if (currentParam[0] === 'retention')
                     {   console.log('ret valu',currentParam[1]);
                        retentionPeriod =currentParam[1] }
                }
                if(retentionPeriod === '')
                {
                    setAlertOpen1(true)
                    setErrorMessage("Select call type to stop recording")
                }
                else
                {
                await backend.recordingActions(
                    sessionStorage.getItem('agent-snippet-recording-token'),
                    sessionStorage.getItem('agent-snippet-recording-env'),
                    sessionStorage.getItem('agent-snippet-recording-convId'),
                    sessionStorage.getItem('agent-snippet-recording-participantId'),
                    "stop",
                    sessionStorage.getItem('agent-snippet-recording-agent'),
                    sessionStorage.getItem('agent-snippet-recording-queue'),
                    retentionPeriod
                )
                }
                // await utils.sleep(2000)
                setDisButton(false)
                setLoading(false)
                setButtonLabel("Start")
                setAlertOpen(true)
                setStatusMessage((local[sessionStorage.getItem('agent-snippet-recording-language')].stopMessage))
                setFilterByValue(null)
                filterValue = null
            }
        }
        catch (err) {
            console.log(err)
            setLoading(false)
            setDisButton(false)
            setAlertOpen1(true)
            setErrorMessage("Action failed. Please try again")
        }
    }

    const BlackTextTypography = withStyles({
        root: {
            color: "#000000"
        }
    })(Typography);


    return (
        <Fragment>
            <AppBar elevation={0} style={{ background: "#FFFFFF" }} position="fixed">
                <Toolbar>
                    <div hidden={!loading} style={{ float: "left" }}>
                        <CircularProgress size={18} style={{ marginTop: 10 }} />
                    </div>
                    <div style={{ float: "left" }}>
                        <Button variant="outlined" onClick={handleButtonClick} style={{ float: "left", textTransform: "none", marginTop: 25 }} 
                        // disabled={disableButton}
                        disabled = {disButton}
                        >
                            {
                                buttonLabel == "Start" && disButton == false &&(
                                    <RadioButtonCheckedIcon style={{ color: "#2a60c8" }} />

                                )
                            }
                            {
                                buttonLabel == "Start" && disButton == true && (
                                    <RadioButtonCheckedIcon style={{ color: "gray" }} />
                                )
                            }
                            {
                                buttonLabel == "Stop" && disButton == false && (
                                    <StopCircleIcon style={{ color: "#2a60c8" }} />
                                )
                            }
                            {
                                buttonLabel == "Stop" && disButton == true && (
                                    <StopCircleIcon style={{ color: "gray" }} />
                                )
                            }&nbsp;
                            {buttonLabel === "Start" && (
                                local[sessionStorage.getItem('agent-snippet-recording-language')] && local[sessionStorage.getItem('agent-snippet-recording-language')].startButton
                            )}
                            {buttonLabel === "Stop" && (
                                local[sessionStorage.getItem('agent-snippet-recording-language')] && local[sessionStorage.getItem('agent-snippet-recording-language')].stopButton
                            )}
                        </Button>
                    </div>

                </Toolbar>
            </AppBar>
            <Snackbar
                open={alertOpen}
                autoHideDuration={3000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity="success">
                    {statusMessage}
                </Alert>
            </Snackbar>
            <Snackbar
                open={alertOpen1}
                autoHideDuration={3000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
        </Fragment >
    )
};
export default AgentDesktopView;