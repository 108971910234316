import React from "react";
import styled from 'styled-components';

const TitleDiv = styled.div`
    margin: 25px;
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    color: #172B52;
    font-family: "Lato", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
`;

const WelcomeGreet = (props) => {
    return (
        <TitleDiv>
            {(props.tabName === "start") ?
                <>
                    <span>Hi</span><span id="username"> {props.userName}</span>
                    <div>
                        Welcome to the Snipped Recordings for Genesys Cloud application
                    </div>
                </> :
                <span>{props.tabTitle}</span>
            }

        </TitleDiv >
    )

};

export default WelcomeGreet;