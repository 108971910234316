import './PureCloudLogin.css'
import React, { useEffect, Fragment } from 'react'
import config from '../../../config'
import { GenesysCloudClientAuthenticator, authenticatorFactory, IAuthData } from 'genesys-cloud-client-auth';

const PureCloudLogin = () => {
    useEffect(() => {
        
        console.log('PureCloudLogin component')
        const queryStringData = {
            response_type: 'token',
            client_id: config.purecloud.clientId,
            redirect_uri: `${window.location.protocol}//${window.location.host}`
        }
        const param = Object.keys(queryStringData).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(queryStringData[k])}`).join('&')
        var url = new URL(window.location.href);
        var en = url.searchParams.get("environment");
        var ci = queryStringData.client_id;
        var ta = queryStringData.redirect_uri;
        if(url.searchParams.get("redirecturl"))
            ta = queryStringData.redirect_uri+"/"+url.searchParams.get("redirecturl");
        var pa = url.searchParams.get("usePopupAuth");
        var re =''
        if(url.searchParams.get("redirecturl"))
            re = queryStringData.redirect_uri+"/"+url.searchParams.get("redirecturl");
        else
            re = queryStringData.redirect_uri
        console.info('en: ' + en);
        console.info('ci: ' + ci);
        console.info('ta: ' + ta);
        console.info('re: ' + re);
        console.info('pa: ' + pa);
        
        if (pa === "true") {
            console.info('loading popup auth');
            re= `${window.location.protocol}//${window.location.host}/popuplogin`
            Object.entries(localStorage).forEach(([key, val]) => {
              if (!val.includes('gc-ca')) {
                console.debug('deleting - ', key)
                delete localStorage[key]
              };
            });
  
            const clientId = ci;
            const authenticator= authenticatorFactory(clientId, {
              /* these are the defaults */
              environment: en,
              persist: false,
              storageKey: 'gc_client_auth_data',
              debugMode: false
            });
  
            console.info('updated re:', re.replace("login", "popuplogin"))
  
            authenticator.loginImplicitGrant({
              // if left falsy, the `https://apps.{env}/client-auth/` app will be used for the redirectUri
              // else, your redirect will need to implement the parsing of the token (see next step)
              redirectUri: re,
              usePopupAuth: true,
              popupTimeout: 60000 // default amount
              //}).then((data: IAuthData) => {
            }).then((data) => {
              console.log('success')
              /* data will contain authentication information like accessToken */
              sessionStorage.setItem('purecloud_token', data.accessToken);
              sessionStorage.setItem('purecloud-csp-token', data.accessToken)
              sessionStorage.setItem('agent-snippet-recording-token', data.accessToken)
              localStorage.setItem('purecloud-csp-usePopupAuth', true)
              if (!ta) {
                console.info('target_page not present, no action will be taken');
              } else {
                console.info(`target_page=[${ta}], redirecting`);
                window.location.href = ta;
              }
            }).catch(error => {
              console.error('error in popup authentication')
              console.error(JSON.stringify(error))
              /* authentication failed */
              if (error.name === 'TIMEOUT_ERROR') {
                // this error will trigger after the `popupTimeout` expires without valid auth data saved
                //  this could be an indication that the user has popups blocked
                //  you will need to add application logic to handle these errors
              }
            });
          }
        else{
            console.log(`Authorization request: ${param}`)
        window.location = `https://login.${sessionStorage.getItem('purecloud-csp-env')}/oauth/authorize?${param}`
        }
    }, [])

    return (<Fragment></Fragment>)
}

export default PureCloudLogin