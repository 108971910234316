/*
*   NOTE: This sample uses ES6 features
*/
import config from '../../../../config'
const platformClient = window.require('platformClient');

const appConfig = config;
// JQuery Alias
const $ = window.$;

// Relative path to wizard page from config's redirectUri
//const WIZARD_PAGE = "/index.html";

const pageSize = 500;
/**
 * WizardApp class that handles everything in the App.
 */
class WizardApp {
    constructor(redirectUri) {
        this.pcEnvironment = null;
        this.org = null;

        // PureCloud Javascript SDK clients
        this.platformClient = platformClient;
        this.purecloudClient = this.platformClient.ApiClient.instance;
        this.purecloudClient.setPersistSettings(true, appConfig.integrationType);
        this.redirectUri = redirectUri;

        // PureCloud API instances
        this.usersApi = new this.platformClient.UsersApi();
        this.integrationsApi = new this.platformClient.IntegrationsApi();
        this.authApi = new this.platformClient.AuthorizationApi();
        this.oAuthApi = new this.platformClient.OAuthApi();
        this.organizationApi = new this.platformClient.OrganizationApi();

        // Language default is english
        // Language context is object containing the translations
        this.language = appConfig.defaultLangTag;

        this.integrationType = appConfig.integrationType;

        this.appName = appConfig.appName;
        this.appUrl = appConfig.appUrl;

        this.installationData = appConfig.provisioningInfo;
    }

    //// =======================================================
    ////      ENTRY POINT
    //// =======================================================
    start() {
        return new Promise((resolve, reject) => {
            this._setupClientApp()
                .then(() => {
                    return this._pureCloudAuthenticate()
                })
                .then((data) => {
                    if (data && data.accessToken) {
                        sessionStorage.setItem('purecloud-csp-token', data.accessToken);
                    }
                    console.debug('Setup success');
                    return this.organizationApi.getOrganizationsMe()
                }).then((orgData) => {
                    this.org = orgData
                    console.debug(this.org)
                    sessionStorage.setItem('orgId', this.org.id)
                    sessionStorage.setItem('orgName', this.org.thirdPartyOrgName)

                    return resolve();
                })
                .catch((err) => {
                    console.error(err);
                    reject(err)
                });
        });
    }

    /**
     * First thing that needs to be called to setup up the PureCloud Client App
     */
    _setupClientApp() {
        // Snipped from URLInterpolation example: 
        // https://github.com/MyPureCloud/client-app-sdk
        const queryString = window.location.search.substring(1);
        const pairs = queryString.split('&');

        let pcEnv = null;
        let langTag = null;

        for (let i = 0; i < pairs.length; i++) {
            const currParam = pairs[i].split('=');

            if (currParam[0] === 'langTag') {
                langTag = currParam[1];
            } else if (currParam[0] === 'environment') {
                pcEnv = currParam[1];
            }
        }

        // Stores the query parameters into sessionStorage
        // If query parameters are not provided, try to get values from sessionStorage
        // Default values if it does not exist.
        if (pcEnv) {
            sessionStorage.setItem('purecloud-csp-env', pcEnv);
        } else if (sessionStorage.getItem('purecloud-csp-env')) {
            pcEnv = sessionStorage.getItem('purecloud-csp-env');
        } else {
            // Use default PureCloud region
            pcEnv = appConfig.defaultPcEnv;
            sessionStorage.setItem('purecloud-csp-env', pcEnv);
        }

        this.pcEnvironment = pcEnv;
        console.debug("Environment:" + this.pcEnvironment);

        if (langTag) {
            sessionStorage.setItem('purecloud-csp-langTag', langTag);
        } else if (sessionStorage.getItem('purecloud-csp-langTag')) {
            langTag = sessionStorage.getItem('purecloud-csp-langTag');
        } else {
            // Use default Language
            langTag = appConfig.defaultLangTag;
        }
        this.language = langTag;

        console.debug("Language:" + this.language);

        return new Promise((resolve) => { resolve() })

        // Get the language context file and assign it to the app
        // For this example, the text is translated on-the-fly.
        // return new Promise((resolve, reject) => {
        //     const fileUri = '../languages/' + this.language + '.json';
        //     $.getJSON(fileUri)
        //         .done(data => {
        //             this.displayPageText(data);
        //             resolve();
        //         })
        //         .fail(xhr => {
        //             //reject(new Error(`Language file not found - "${this.language}.json"`));
        //             console.error(`Language file not found - "${this.language}.json. Loading default language"`)
        //             this.language = 'en-us'
        //             sessionStorage.setItem('purecloud-csp-langTag', 'en-us');
        //             this.loadDefaultLanguage()
        //             resolve();
        //         });
        // });
    }

    loadDefaultLanguage() {
        $.getJSON('../languages/en-us.json')
            .done(data => {
                this.displayPageText(data);
            })
            .fail(xhr => {
                //reject(new Error(`Language file not found - "${this.language}.json"`));
                console.error(`Language file not found - "${this.language}.json"`)
            });
    }

    /**
    * Renders the proper text language into the web pages
    * @param {Object} text  Contains the keys and values from the language file
    */
    displayPageText(text) {
        $(document).ready(() => {
            for (let key in text) {
                if (!text.hasOwnProperty(key)) continue;
                $("." + key).text(text[key]);
            }
        });
    }

    /**
     * Authenticate to PureCloud (Implicit Grant)
     * @return {Promise}
     */
    _pureCloudAuthenticate() {
        console.debug('_pureCloudAuthenticate clientId =', appConfig.purecloud.clientId);
        console.debug('_pureCloudAuthenticate redirectUri =', this.redirectUri);
        console.debug('_pureCloudAuthenticate pcEnvironment =', this.pcEnvironment);

        this.purecloudClient.setEnvironment(this.pcEnvironment);
        return this.purecloudClient.loginImplicitGrant(
            appConfig.purecloud.clientId,
            this.redirectUri,
            { state: ('pcEnvironment=' + this.pcEnvironment) });
    }

    /**
    * Checks if the product is available in the current Purecloud org by
    * validating the integration type being enabled for the org
    * @return {Promise.<Boolean>}
    */
    validateProductAvailability() {
        // premium-app-usertemplates         
        return this.getEntities(this.integrationsApi, 'getIntegrationsTypes')
            .then((entities) => {
                if (entities.filter((integType) => integType.id === this.integrationType)[0]) {
                    console.debug("PRODUCT AVAILABLE");
                    return (true);
                } else {
                    console.debug("PRODUCT NOT AVAILABLE");
                    return (false);
                }
            });
    }

    /**
    * Checks if any configured objects are still existing. 
    * This is based on the appName
    * @returns {Promise.<Boolean>} If any installed objects are still existing in the org. 
    */
    isExisting() {
        const promiseArr = [];

        promiseArr.push(this.getExistingRoles());
        promiseArr.push(this.getExistingAuthClients());

        return Promise.all(promiseArr)
            .then((results) => {
                console.debug('isExisting, results = ' + JSON.stringify(results, null, 3));
                return results[0].length || results[1].length;
            });
    }

    /**
    * Get existing roles in purecloud based on appName
    * @returns {Promise.<Array>} PureCloud Roles
    */
    getExistingRoles() {
        const authOpts = {
            'name': appConfig.appName.replace(/\(/g, '\\(').replace(/\)/g, '\\)') + '*', // Wildcard to work like STARTS_WITH, need to escape ) and (
            'userCount': false
        };
        return this.getEntities(this.authApi, 'getAuthorizationRoles', authOpts);
    }


    /**
     * Get existing authetication clients based on the prefix
     * @returns {Promise.<Array>} Array of PureCloud OAuth Clients
     */
    getExistingAuthClients() {
        return this.getEntities(this.oAuthApi, 'getOauthClients')
            .then((entities) => {
                return entities.filter(entity => entity.name && entity.name.startsWith(this.appName));
            });
    }

    /**
     * Get details of the current user
     * @return {Promise.<Object>} PureCloud User data
     */
    getUserDetails() {
        const opts = { 'expand': ['authorization'] };
        return this.usersApi.getUsersMe(opts);
    }

    //// =======================================================
    ////      PROVISIONING
    //// =======================================================

    /**
     * Final Step of the installation wizard. 
     * Create the PureCloud objects defined in provisioning configuration
     * The order is important for some of the PureCloud entities.
     */
    installConfigurations() {

        console.debug('Installing configurations');

        $.LoadingOverlay("show", {
            image: "",
            fontawesomeResizeFactor: 2.5,
            fontawesome: "fa fa-spinner fa-spin",
            fontawesomeColor: '#DDDDDD',
            fontawesomeOrder: 1,
            text: "Configuring your App",
            textResizeFactor: 0.17,
            textOrder: 2,
            textClass: "adjust-text",
            background: "rgba(240, 240, 255, 0.9)"
        });

        // Get the app instance from integration
        return this.getAppInstance()
            // Create OAuth client after role (required) and pass to server
            .then(() => this.addRole())

            // Create OAuth client after role (required) and pass to server
            .then((role) => this.addAuthClient(role))

            // Add organization in Snipped Recording configuration table and notifies the customer and team
            .then((oauth) => this.addOrganization(oauth))

            // When everything's finished, log a success message.
            .then(() => {
                this.logInfo('Request has been placed successfully!');
                setTimeout(function () { $.LoadingOverlay("hide"); }, 1500);
            }).catch((err) => {
                this.logInfo('Error occured!');
                setTimeout(function () { $.LoadingOverlay("hide"); }, 1500);
                throw err;
            });
    }

    getAppInstance() {
        return this.getEntities(this.integrationsApi, 'getIntegrations')
            .then(entities => {
                return (entities.find(entity => entity.integrationType.id === this.integrationType && entity.name === this.appName));
            })
            .then(integration => {
                if (!(integration && integration.id)) {
                    throw new Error(`Could not find integration - "${this.appName}".`);
                }
                return integration;
            });
    }

    /**
     * Add PureCLoud role based on installation data
     * @returns {Promise}
     */
    async addRole() {
        console.debug('Adding role');

        this.logInfo('Creating Application Roles');
        
        await this.addRoles();

        this.logInfo('Creating Backend role ' + appConfig.appName);

        const roleBody = {
            name: this.installationData.roles[0].name,
            description: this.installationData.roles[0].description,
            permissionPolicies: this.installationData.roles[0].permissionPolicies
        };

        let role;

        // Create the role
        return this.authApi.postAuthorizationRoles(roleBody)
            .then((data) => {
                this.logInfo('Created role ' + appConfig.appName);
                role = data;
                return this.getUserDetails();
            })
            .then((user) => {
                // Assign the role to the user
                // Required before you can assign the role to an Auth Client.
                return this.authApi.putAuthorizationRoleUsersAdd(role.id, [user.id]);
            })
            .then(() => {
                this.logInfo('Assigned role ' + appConfig.appName + ' to user');
                return role;
            })
            .catch((err) => {
                console.error('Role creation error:' + JSON.stringify(err, null, 3));
                throw err;
            });
    }

    addRoles() {
        // Create the roles needed for determining UI access - Admin / Supervisor
        const promises = []
        for (const role of this.installationData.roles) {
            if (role.name === this.appName) continue

            console.log('Creating role:', role.name)
            promises.push(this.authApi.postAuthorizationRoles({ name: role.name, description: role.description, permissionPolicies: role.permissionPolicies }))
        }

        return Promise.all(promises)
    }

    /**
     * Add PureCLoud instance based on installation data
     * @returns {Promise.<Array>} PureCloud OAuth objects
     */
    addAuthClient(role) {
        console.debug('Adding oauth client');
        this.logInfo('Creating oauth client');

        const oauthClient = {
            name: this.installationData.oauth.name,
            description: this.installationData.oauth.description,
            roleIds: [role.id],
            authorizedGrantType: "CLIENT_CREDENTIALS"
        };

        return this.oAuthApi.postOauthClients(oauthClient)
            .then((data) => {
                this.logInfo(`Created OAuth client "${this.installationData.oauth.name}"`);
                return data;
            })
            .catch((err) => {
                console.error('Oauth creation error:' + JSON.stringify(err, null, 3));
                throw err;
            })
    }

    //// =======================================================
    ////      ORGANIZATION
    //// =======================================================

    addOrganization(oauth) {
        console.debug('Adding organization');
        this.logInfo('Adding org');

        const orgID = sessionStorage.getItem('orgId');
        const orgName = sessionStorage.getItem('orgName')
        console.debug(appConfig.endpoints.backend + '/addOrg/' + orgID)

        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'POST',
                headers: {
                    'token': sessionStorage.getItem('purecloud-csp-token'),
                    'env': sessionStorage.getItem('purecloud-csp-env'),
                    'tokensource': 'purecloud'
                },
                url: appConfig.endpoints.backend + '/addOrg/' + orgID,
                contentType: 'application/json',
                dataType: 'json',
                data: JSON.stringify({ clientId: oauth.id, clientSecret: oauth.secret, orgName: orgName,version: "AF" }),
                success: function (result) {
                    console.debug('Added org');
                    resolve()
                },
                error: function (xhr, status, error) {
                    console.error('addOrganization, xhr = ' + JSON.stringify(xhr, null, 3));
                    console.error('addOrganization, status = ' + JSON.stringify(status, null, 3));
                    console.error('addOrganization, error = ' + JSON.stringify(error, null, 3));
                    reject("Error in adding organisation.");
                }
            });
        });
    }


    //// =======================================================
    ////      DISPLAY/UTILITY FUNCTIONS
    //// =======================================================

    /**
     * Shows an overlay with the specified data string
     * @param {string} data 
     */
    logInfo(data) {
        if (!data || (typeof (data) !== 'string')) data = "";

        $.LoadingOverlay("text", data);
    }


    //// =======================================================
    ////      UTILITIES
    //// =======================================================

    getEntities(api, func, options) {
        const entities = [];
        let pageNumber = 1;

        const getEntitiesWorker = () => {
            return api[func]({ ...options, pageSize, pageNumber })
                .then((data) => {
                    console.debug(`${func}, pageSize = ${pageSize}, pageNumber = ${pageNumber}, entities = ${data.entities.length}`);
                    entities.push(...data.entities);
                    return (pageNumber++ < data.pageCount) ? getEntitiesWorker() : entities;
                });
        }
        return getEntitiesWorker();
    }

}


export default WizardApp;