import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import HomeIcon from "@material-ui/icons/Home";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import HelpIcon from '@mui/icons-material/Help';
//import { local } from "../../language/language";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },

  title: {
    flexGrow: 1,
  },
}));

export default function Header(props) {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () =>
    history.push({
      pathname: "/help",
    });

  return (
    <div className={classes.root}>
      <AppBar style={{ background: "#444A52" }} position="fixed">
        <Toolbar style={{minHeight: '36px'}}>
          <IconButton color="inherit" href="/home" edge="start">
            <HomeIcon />
          </IconButton>
          <Typography noWrap style={{ flex: 1 }}>
            {"Snipped Recordings for Genesys Cloud"}
          </Typography>
          <Button onClick={handleClick} style={{float:"right"}} color="inherit">
          <HelpIcon />
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}
