import React, { useRef, useEffect } from "react";

import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import DoneRoundedIcon from "@material-ui/icons/DoneRounded";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";

import moment from "moment";

import "./DateTimeRangePicker.css";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
export const DateTimeRangePicker = (props) => {
  const DATETIME_FORMAT = "YYYY-MM-DDTHH:mm";

  const [fromTimeRange, setFromTimeRange] = React.useState(props.fromTimeRange);
  const [toTimeRange, setToTimeRange] = React.useState(props.toTimeRange);
  const [validRange, setValidRange] = React.useState(true);
  const inputFromTimeRange = React.createRef();
  const inputToTimeRange = React.createRef();

  const prevProps = usePrevious(props);
  useEffect(() => {
    // console.log("PrevProps", prevProps);
    // console.log("Props", props);
    if (
      prevProps &&
      (props.fromTimeRange !== prevProps.fromTimeRange ||
        props.toTimeRange !== prevProps.toTimeRange)
    ) {
      setFromTimeRange(props.fromTimeRange);
      setToTimeRange(props.toTimeRange);
    }
  });

  const handleFromChange = (event) => {
    const newValue = moment(event.target.value);
    let isValidRange;
    //logic to check 30 days duration and valid chronological time range
    if(newValue.isBefore(toTimeRange)&&(moment(toTimeRange).diff(moment(newValue),'days')<=30))
      isValidRange=true
    else
      isValidRange=false
    setFromTimeRange(newValue);
    setValidRange(isValidRange);
    props.onChange({
      from: newValue,
      to: toTimeRange,
      validRange: isValidRange,
    });
  };

  const handleToChange = (event) => {
    const newValue = moment(event.target.value);
    let isValidRange;
    // logic to check 30 days duration and valid chronological time range
    if(newValue.isAfter(fromTimeRange)&&(moment(newValue).diff(moment(fromTimeRange),'days')<=30))
      isValidRange=true
    else
      isValidRange=false
    setToTimeRange(newValue);
    setValidRange(isValidRange);
    props.onChange({
      from: fromTimeRange,
      to: newValue,
      validRange: isValidRange,
    });
  };

  const renderValidTimeRange = () => {
    let tooltipCaption;
    if(validRange)
      tooltipCaption = "Valid time range"
    else
    {
      if(fromTimeRange.isBefore(toTimeRange))
      {
        if((moment(toTimeRange).diff(moment(fromTimeRange),'days')<=30))
        {
          tooltipCaption = "Valid time range"
          setValidRange(true)
        }
        else
        tooltipCaption = "Time range must be within 30 days"
      }
      else
        tooltipCaption = "Invalid chronological time range"
    }

    return (
      <Tooltip title={tooltipCaption}>
        {validRange === true ? (
          <DoneRoundedIcon className="valid-icon" />
        ) : (
          <WarningRoundedIcon className="warning-icon" />
        )}
      </Tooltip>
    );
  };

  return (
    <div className="InputFromTo">
      <TextField
        ref={inputFromTimeRange}
        type="datetime-local"
        defaultValue={fromTimeRange.format(DATETIME_FORMAT)}
        variant="outlined"
        onChange={handleFromChange}
        value={fromTimeRange.format(DATETIME_FORMAT)}
      />
      &nbsp;&nbsp;{props.localUntil}&nbsp;&nbsp;
      <span className="InputFromTo-to">
        <TextField
          ref={inputToTimeRange}
          type="datetime-local"
          defaultValue={toTimeRange.format(DATETIME_FORMAT)}
          variant="outlined"
          onChange={handleToChange}
          value={toTimeRange.format(DATETIME_FORMAT)}
        />
      </span>
      <span>{renderValidTimeRange()}</span>
    </div>
  );
};
