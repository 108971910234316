import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@material-ui/core/styles';
import "./ResultView.css";

export const ResultView = (props) => {
  const [selectedRowId, setSelectedRowId] = React.useState();
  const [alertOpen, setAlertOpen] = React.useState(true);
  const [pageSize, setPageSize] = React.useState(25)

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  const columnheaders = [
    {
      field: "agentName",
      headerName: "Name",
      sortable: true,
      resizable: false,
      minWidth: 100,
      flex: 0.65,
      type: 'string',
      headerClassName: 'super-app-theme--header'
    },
    {
      field: "callerId",
      headerName: "Caller ID",
      sortable: true,
      resizable: false,
      minWidth: 100,
      flex: 0.35,
      type: 'string',
      headerClassName: 'super-app-theme--header'

    },
    {
      field: "conversationStart",
      headerName: "Start Date",
      sortable: true,
      resizable: false,
      minWidth: 100,
      flex: 0.65,
      type: 'dateTime',
      headerClassName: 'super-app-theme--header'

    },
    {
      field: "durationInMS",
      type: 'number',
      hide: true,
    },
    {
      field: "duration",
      headerName: "Duration",
      sortable: true,
      resizable: false,
      minWidth: 100,
      flex: 0.35,
      type: 'string',
      headerClassName: 'super-app-theme--header',
      sortComparator: (v1, v2, param1, param2) =>
        param1.api.getCellValue(param1.id, 'durationInMS') -
        param2.api.getCellValue(param2.id, 'durationInMS'),
    },
    {
      field: "originatingDirection",
      headerName: "Direction",
      sortable: true,
      resizable: false,
      minWidth: 100,
      flex: 0.35,
      type: 'string',
      headerClassName: 'super-app-theme--header',
    },
    {
      field: "queueName",
      headerName: "Queue",
      sortable: true,
      resizable: false,
      minWidth: 100,
      flex: 0.35,
      type: 'string',
      headerClassName: 'super-app-theme--header'

    },
    {
      field: "recordingStatus",
      headerName: "Recording Status",
      sortable: true,
      resizable: false,
      minWidth: 100,
      flex: 0.35,
      type: 'string',
      headerClassName: 'super-app-theme--header'

    }

  ]
  const currentlySelected = (params) => {
    setSelectedRowId(params.row.conversationId);
    props.onConversationSelect(params.row);
  }
  const useStyles = makeStyles({
    root: {
      '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
        outline: 'none',
      },
    }
  });
  const classes = useStyles();
  if (props.noResultsMessage !== undefined) {
    return (
      <Container className="resultContainer" component={Paper}>
        <Snackbar
          open={alertOpen}
          autoHideDuration={5000}
          onClose={handleAlertClose}
        >
          <Alert onClose={handleAlertClose} severity="error">
            {props.noResultsMessage}
          </Alert>
        </Snackbar>
        <p className="noResults">{"No search results"}</p>
      </Container>
    );
  } else {
    if (props.totalHits > 0) {
      console.log("Selected row ConversationID", selectedRowId);
      return (
        props.conversationData.length > 0 && (
          <div style={{ height: 300, width: "100%" }}>
            <DataGrid
              className={classes.root}
              rowHeight={30}
              headerRowHeight={30}
              rows={props.conversationData}
              columns={columnheaders}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 25, 50]}
              pagination
              disableColumnFilter
              disableColumnMenu
              hideFooterSelectedRowCount
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              onCellClick={currentlySelected}

            />
          </div>
        )
      );
    } else {
      return (
        <Container className="resultContainer" component={Paper}>
          <p className="noResults">{"No search results"}</p>
        </Container>
      );
    }
  }
};
export default ResultView;