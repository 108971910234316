// private config
const env = process.env.REACT_APP_CUSTOM_ENV.trim()
console.log('env:', env)
const appName = env === "development" ? "Snipped Recordings for Genesys Cloud DEV" : (env === "qa" || env === "test") ? "Snipped Recordings for Genesys Cloud QA" : "Snipped Recordings for Genesys Cloud";
const provisioningInfo = {
    roles: [
        {
            name: appName,
            description: 'Generated role - Snipped Recordings for Genesys Cloud backend',
            permissionPolicies: [
                {
                    domain: 'integrations',
                    entityName: 'integration',
                    actionSet: ["view"],
                    allowConditions: false
                },
                {
                    domain: 'directory',
                    entityName: 'user',
                    actionSet: ["view"],
                    allowConditions: false
                }]
        },
        {
            name: appName + ' Admin',
            description: 'Generated role - Snipped Recordings for Genesys Cloud Admin access',
            permissionPolicies: [
                {
                    domain: 'scripter',
                    entityName: 'publishedScript',
                    actionSet: ["view"],
                    allowConditions: false
                },
                {
                    domain: 'integration',
                    entityName: 'snippedRecordings',
                    actionSet: ["*"],
                    allowConditions: false
                }
            ]
        },
        {
            name: appName + ' Supervisor',
            description: 'Generated role - Snipped Recordings for Genesys Cloud Supervisor access',
            permissionPolicies: [
                {
                    domain: 'scripter',
                    entityName: 'publishedScript',
                    actionSet: ["view"],
                    allowConditions: false
                },
                {
                    domain: 'integration',
                    entityName: 'snippedRecordings',
                    actionSet: ["view"],
                    allowConditions: false
                }
            ]
        },
        {
            name: appName + ' Agent',
            description: 'Generated role - Snipped Recordings for Genesys Cloud Agent access',
            permissionPolicies: [
                {
                    domain: 'scripter',
                    entityName: 'publishedScript',
                    actionSet: ["view"],
                    allowConditions: false
                },
                {
                    domain: 'integration',
                    entityName: 'snippedRecordings',
                    actionSet: ["agent"],
                    allowConditions: false
                }
            ]
        }
    ],
    oauth: {
        name: appName + ' OAuth',
        description: `Generated OAuth Client that is used by the application backend`,
        authorizedGrantType: 'CLIENT_CREDENTIALS'
    }
};

const development = {
    purecloud: {
        clientId: 'd4dfa94d-d68a-417f-800c-baf332b178c1', //Csp org client ID
    },
    pureconnect: {
        primary: '',
        backup: '',
        //Leave identityProviderName blank if not using SSO and using IC auth
        //If using windows authentication, make this "Windows Authentication" exactly.
        identityProviderName: 'Windows Authentication',
        baseUrl: 'http://<hostname>:8018/icws',
        applicationName: 'Genesys Template'
    },
    pureengage: {

    },
    product: 'purecloud',
    endpoints: {
        subscription: 'https://cwejblr0oe.execute-api.us-east-1.amazonaws.com/dev/subscription/validate',
        backend: 'https://4riyzis15g.execute-api.us-east-1.amazonaws.com/dev'
    },
    region : "mypurecloud.com",
    defaultRetentionPeriod : 365,
    retentionTableName : 'Snippet Recordings - Retention',
    defaultPcEnv: "mypurecloud.com",
    defaultLangTag: "en-us",
    integrationType: "premium-app-example",
    //appUrl: "http://localhost:3000/",
    appUrl: "https://d23rqk5ss1ucor.cloudfront.net/",
    provisioningInfo: provisioningInfo,
    appName: appName
}

const qa = {
    purecloud: {
        clientId: 'd4dfa94d-d68a-417f-800c-baf332b178c1',
    },
    pureconnect: {
        primary: '',
        backup: '',
        //Leave identityProviderName blank if not using SSO and using IC auth
        //If using windows authentication, make this "Windows Authentication" exactly.
        identityProviderName: 'Windows Authentication',
        baseUrl: 'http://<hostname>:8018/icws',
        applicationName: 'Genesys Template'
    },
    pureengage: {

    },
    product: 'purecloud',
    endpoints: {
        subscription: 'https://y7vp7qnt6f.execute-api.us-east-1.amazonaws.com/test/subscription/validate',
        backend: 'https://2yyrtxuc3m.execute-api.us-east-1.amazonaws.com/test'
    },
    region : "mypurecloud.de",
    defaultRetentionPeriod : 365,
    retentionTableName : 'Snippet Recordings - Retention',
    defaultPcEnv: "mypurecloud.com",
    defaultLangTag: "en-us",
    integrationType: "premium-app-example",
    appUrl: "https://d2sdnnuzc6k0yc.cloudfront.net/",
    provisioningInfo: provisioningInfo,
    appName: appName
}

const production = {
    purecloud: {
        clientId: 'b90bc355-bb53-4e1e-b4f1-bb6143135b8a', 
    },
    pureconnect: {
        primary: '',
        backup: '',
        //Leave identityProviderName blank if not using SSO and using IC auth
        //If using windows authentication, make this "Windows Authentication" exactly.
        identityProviderName: 'Windows Authentication',
        baseUrl: 'http://<hostname>:8018/icws',
        applicationName: 'Genesys Template'
    },
    pureengage: {

    },
    product: 'purecloud',
    endpoints: {
        subscription: 'https://ukv2z4due3.execute-api.us-east-1.amazonaws.com/prod/subscription/validate',
        backend: 'https://3eyt2wgwz7.execute-api.us-east-1.amazonaws.com/prod'
    },
    region : "mypurecloud.de",
    defaultRetentionPeriod : 365,
    retentionTableName : 'Snippet Recordings - Retention',
    defaultPcEnv: "usw2.pure.cloud",
    defaultLangTag: "en-us",
    integrationType: "premium-app-snipped-recordings",
    appUrl: "https://snippetrecordingspremium.genesysinnoapps.com/",
    provisioningInfo: provisioningInfo,
    appName: appName
}

let config
switch (env) {
    case 'production': config = production; break;
    case 'development': config = development; break;
    case 'qa': config = qa; break;
    case 'test': config = qa; break;
    default: break;
}

config.sendAsEmailAddr= 'PS-NoReply@genesys.com';
config.installationNotificationAddr= 'ExpertAppsInstall@genesys.com';

export default config
