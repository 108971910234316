import React from "react";
import { Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import { makeStyles } from "@material-ui/core/styles";

import config from "../config";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
 import PureCloudLogin from "../components/login/purecloud/PureCloudLogin";
import Main from "../components/Main";
import Unauthorized from "../components/unauthorized/Unauthorized";
import Home from "../components/home/Home";
 import HelpAbout from "../components/documentation/HelpAbout";
import Header from "../components/header/Header";
import AgentDesktopView from "../components/AgentDesktopView"
import PopUpLogin from "../components/login/purecloud/PopUpLogin";

import Index from '../components/wizard/Index/index';
import InstallView from '../components/wizard/Install/index'
import SummaryView from '../components/wizard/summary'

const history = createBrowserHistory();
 const LoginPage = config.product === "purecloud" ? PureCloudLogin : "";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function AppRouter() {
  const classes = useStyles();
  

  return (
    <Router history={history}>
        <Switch>

          <PublicRoute path="/" exact={true} component={Index} history={history} />
          <PublicRoute path="/install" exact={true} component={InstallView} history={history} />
          <PublicRoute path="/summary" exact={true} component={SummaryView} history={history} />

          <PublicRoute
            path="/main"
            exact={true}
            component={Main}
            history={history}
          />
          <PublicRoute path="/login" component={LoginPage} />
          <PublicRoute path="/unauthorized" component={Unauthorized} />
          <PrivateRoute
            path="/home"
            component={() => <Home />}
            history={history}
          />
          <PublicRoute path="/help" component={HelpAbout} history={history} />
          <PublicRoute path="/agentdesktop" component={AgentDesktopView} history={history} />
          <PublicRoute path="/popuplogin" component={PopUpLogin} history={history} />
        </Switch>
    </Router>
  );
}
