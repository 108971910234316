import React, { Fragment, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

import './MarkdownViewer.css';

export default function MarkdownViewer(props) {
  const [rawMarkdown, setRawMarkdown] = React.useState(undefined);

  useEffect(() => {
    if (props.markdownFilePath) {
      const markdownFile = require (`${props.markdownFilePath}`);
      fetch(markdownFile)
        .then(response => {
          return response.text();
        })
        .then(text => {
          setRawMarkdown(text);
        })
      }
    }, [props.markdownFilePath]);

  if (rawMarkdown) {
    return (
      <ReactMarkdown
          className="markdownViewer"
          remarkPlugins={ [[gfm, {tablePipeAlign: true, tableCellPadding: true}]] }>
        { rawMarkdown }
      </ReactMarkdown>
    );
  }
  else {
    return (
      <Fragment></Fragment>
    );
  }
}
