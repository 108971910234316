import React from 'react';
import { BeatLoader } from 'react-spinners'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import './WaitingModal.css'

export const WaitingModal = (props) => {
    const { isOpen, header = 'Loading' } = props
    return (
        <Dialog fullWidth maxWidth={"xs"} className="waiting-modal--centered animated fadeIn" open={isOpen}>
            <DialogTitle  className="waiting-modal--centered">{header}</DialogTitle>
            <DialogContent dividers className="waiting-modal--centered">
                <div className="waiting-modal__spinner">
                    <BeatLoader color="currentColor"/>
                </div>
            </DialogContent>
        </Dialog>
    )
}

// export default WaitingModal;
