import React from 'react';

import "./RadialLoader.css";

export const RadialLoader = (props) => {
  return (
    <div
      className="radialLoader" />
  );
}
